import React, {useEffect, useState} from "react";
import "./App.css";
import getCommentsRequest from "./api/comments/getCommentsRequest";
import getAuthorsRequest from "./api/authors/getAuthorsRequest";
import {LikeIcon} from "./LikeIcon";
import {LikeFillIcon} from "./LikeFillIcon";

interface IAuthor {
    id: number;
    name: string;
    avatar: string;
}

interface IPagination {
    page: number;
    size: number;
    total_pages: number;
}

interface IComment {
    id: number;
    created: string;
    text: string;
    author: number;
    authorData?: IAuthor;
    parent?: number;
    likes: number;
    children?: IComment[];
}

// Добавляем объект автора в соответствующий комментарий для удобства
const groupCommentsAndAuthors = (comments: IComment[], authors: IAuthor[]) => {

    return comments.map((comment) => ({
        ...comment,
        authorData: authors.find((author) => author.id === comment.author),
    }));
};

// строим дерево комментариев для отображения
const nestComments = (
    comments: IComment[],
    id: number | null = null,
): IComment[] =>
    comments
        .filter((comment) => comment.parent === id)
        .map((comment) => ({
            ...comment,
            children: nestComments(comments, comment.id),
        }));

const formatTime = new Intl.DateTimeFormat("ru");

// общее количесво лайков и комментариев не посчитать не запросив сразу все комментарии
const getCommentsAndLikesCount = async () => {
    let currentPage = 1;
    let totalPages = null;
    let comments = [];

    do {
        try {
            const {data, pagination} = await getCommentsRequest(currentPage);
            comments.push(...data);
            totalPages = pagination.total_pages;
            currentPage++;
        } catch (e) {
            console.log("error", e);
        }
    } while (totalPages >= currentPage);

    return {
        likes: comments.reduce((acc, item) => acc + item.likes, 0),
        comments: comments.length,
    };
};

function App() {
    const [loading, setLoading] = useState<boolean>(true);
    const [comments, setComments] = useState<IComment[]>([]);
    const [pagination, setPagination] = useState<IPagination>({
        page: 0,
        size: 0,
        total_pages: 0,
    });
    const [authors, setAuthors] = useState<IAuthor[]>([]);
    const [count, setCount] = useState({likes: 0, comments: 0});
    const [error, setError] = useState<string | null>(null);

    const fetchComments = (page = 1) => {
        setLoading(true);
        getCommentsRequest(page)
            .then((data) => {
                setError(null);
                setComments(data.data);
                setPagination(data.pagination);
            })
            .catch((e) => {
                console.log("error", e);
                setError(`${e}, try again`);
            })
            .finally(() => setLoading(false));
    };

    const fetchAuthors = () => {
        getAuthorsRequest()
            .then((data) => {
                setError(null);
                setAuthors(data);
            })
            .catch((e) => {
                console.log("error", e);
                setError(`${e}, try again`);
            });
    };

    useEffect(() => {
        // подсчет количества комментариев и лайков при первом рендере
        getCommentsAndLikesCount().then((data) => setCount(data));

        // загрузка комментариев и авторов при первом рендере
        fetchComments();
        fetchAuthors();
    }, []);

    const handleLoadMore = () => {
        // запрашиваем новую страницу, несмотря на то что я загрузил все комментарии для подсчета их количества при первом рендере использовать их и скрывать на клиенте плохая практика (Страниц может быть 100, юзеру может нужна только первая)
        fetchComments(pagination.page + 1);
    };

    const treeComments = nestComments(
        groupCommentsAndAuthors(comments, authors),
    );

    return (
        <div className="App">
            <div className="Stats">
                <span>{count.comments} комментариев</span>
                <span className="StatsLikes">
                    <LikeIcon />
                    {count.likes}
                </span>
            </div>
            {error ? error : null}
            <br />
            {/*показываем "Загрузка" только при первой загрузке, далее показываем в кнопке*/}
            {!treeComments?.length && loading ? (
                "Загрузка"
            ) : treeComments?.length ? (
                <>
                    {treeComments.map((comment) => (
                        <Comment key={comment.id} comment={comment} />
                    ))}
                    <button
                        className="LoadMoreBtn"
                        onClick={handleLoadMore}
                        disabled={pagination?.page === pagination?.total_pages}
                    >
                        {loading ? "Загружаем..." : "Загрузить еще"}
                    </button>
                </>
            ) : (
                "Loading"
            )}
        </div>
    );
}

export default App;

// Отдельный файл, здесь для удобства
const Comment = ({comment, depth = 0}: {comment: IComment; depth?: number}) => {
    return (
        <>
            <div
                key={comment.id}
                className="Comment"
                style={{marginLeft: `${depth}rem`}}
            >
                <div className="Avatar">
                    <img
                        src={comment?.authorData?.avatar}
                        alt={comment?.authorData?.name}
                    />
                </div>
                <div className="CommentData">
                    <div className="CommentTitle">
                        <div className="CommentName">
                            <span>{comment?.authorData?.name}</span>
                            <span>
                                {formatTime.format(new Date(comment.created))}
                            </span>
                        </div>
                        <div className="CommentLikes">
                            <LikeFillIcon />
                            <span>{comment.likes}</span>
                        </div>
                    </div>
                    <div className="CommentText">{comment.text}</div>
                </div>
            </div>
            {comment?.children?.length
                ? comment.children.map((comment) => (
                      <Comment
                          key={comment.id}
                          comment={comment}
                          depth={++depth}
                      />
                  ))
                : null}
        </>
    );
};
